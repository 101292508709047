@keyframes fadeIn {
  0% {
    transform: translateY(-15%);
  }
  100% {
    transform: translateY(0%);
  }
}

.popup {
  animation-name: fadeIn;
  animation-duration: 0.2s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0;
  list-style-type: none;
  margin: 0px;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.popup li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px;
  transition: all 0.3s;
  white-space: nowrap;
  -webkit-transition: all 0.3s;
}

.popup li:not(.noselect):hover {
  background-color: #e6f7ff;
}

.popup li.noselect {
  cursor: default;
  font-weight: bold;
}

.popup li > i {
  margin-right: 8px;
}
