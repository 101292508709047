.toolbar {
  display: grid;
  grid-template-columns: 80% auto;
  margin-bottom: 2em;
  gap: 1em;
}

.toolbar__actions {
  display: grid;
  grid-template-columns: auto auto;
  height: 100%;
  width: 100%;
  gap: 1em;
}

.toolbar__actions button {
  height: 100%;
}

.massUpdateField {
  margin: 1em 0em;
  display: block;
  min-width: 150px;
}

.pimLink {
  cursor: pointer;
  color: #1890ff;
}

.BaseTable__empty-layer {
  top: 80px !important;
  bottom: 40px !important;
}
