.login-div {
  background: #3393ff;
  height: 100%;
  padding: 4em 4em 5em 4em;

  .login-container {
    display: block;
    max-width: 360px;
    margin: auto;

    .outnordic-logo {
      max-width: 200px;
      margin: 1.5em auto 3em auto;
      display: block;
      cursor: pointer;
    }

    .sign-in-with-google {
      width: 220px !important;
    }

    .sign-in-with-ms {
      background: black;
      color: #fff;
      height: 45px;
      width: 220px;
      padding: 10px;
      font-weight: bold;
      line-height: 23px;
      margin: auto;
      cursor: pointer;
    }

    .copyright-text {
      text-align: center;
      margin-top: 2em;
      color: #fff;
    }
  }
}
