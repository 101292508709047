.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  font-size: 1.4em;
}

.menu-bar {
  margin-bottom: 1.5em;
}

.menu-bar button:first-of-type {
  margin-left: 0;
  margin-right: 2em;
}

.menu-bar button:last-of-type {
  margin-right: 2em;
}

.menu-bar button {
  margin-left: 0.8em;
}

.kebab-menu {
  font-size: 20px;
  float: right;
  font-weight: bold;
  padding: 6px;
  cursor: pointer;
}
