.ant-col-14 {
  width: 100%;
  max-width: 100%;
}

.info-row {
  padding-bottom: 3em;
}

.info-row label {
  display: block;
  margin-bottom: 7px;
}

.info-row .ant-select {
  width: 100%;
}
