@import "~antd/dist/antd.css";

.WelcomePage {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
  gap: 1em;
}

.Listeside {
  text-align: center;
}

.card__icon {
  font-size: 1.5em;
  vertical-align: middle;
}

.Listeside {
  .Banner {
    height: 100px;
    width: 100%;
    background: lightgray;
  }
}
