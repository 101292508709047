.PreparePO {
  .BaseTable__row.BaseTable__row--higlight {
    background-color: rgba(0, 196, 255, 0.13);
  }

  .site-card-border-less-wrapper {
    padding: 30px;
    background: #ececec;
  }

  .BaseTable__header-cell,
  .BaseTable__row-cell {
    border-right: 1px solid #f0f0f0;
  }

  .MuiInputBase-root {
    font-size: inherit;
  }
}

.PreparePO__requiredSteps {
  color: red;
}

.PreparePO__requiredSteps--fullfilled {
  color: green;
}
