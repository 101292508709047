@import "~antd/dist/antd.css";

.ant-layout-sider {
  .menu-logo {
    width: 100%;
    height: 64px;
    font-size: 1.4em;
    line-height: 64px;
    text-align: center;
    color: white;
  }
}

.site-layout {
  .header {
    background: #b76d68;
  }

  .site-layout-background {
    margin-bottom: 20px;
  }
}

.app-name {
  margin-left: 2em;
  font-size: 1.4em;
  line-height: 64px;
  text-align: center;
  color: white;
}

.table-main-filter {
  margin-bottom: 14px;
}
